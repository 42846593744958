import React, { useState } from "react";
import { CircleImage, ImageSize } from "./images";
import "./input.css"
import "./images.css"

const ImageInput = (url: string | null, completion: (file: any) => void) => {
    const [selectedImage, setSelectedImage] = useState<File | null>(null);

    const Avatar = () => {
        if (selectedImage != null) {
            return <div className="image-input-title">
                {CircleImage(URL.createObjectURL(selectedImage), "Portal Avatar", ImageSize.xlarge)}
            </div>
        } else if (url != null) {
            return <div className="image-input-title">
                {CircleImage(url, "Portal Avatar", ImageSize.xlarge)}
            </div>
        } else {
            return <p className="image-input-title">Upload Avatar</p>
        }
    }

    return (
        <div>
            <label className="image-input-label">
                <input
                    className="image-input"
                    type="file"
                    name="PortalAvatar"
                    title="Upload Image"
                    onChange={(event) => {
                        if (event.target.files != null && event.target.files[0] != null) {
                            setSelectedImage(event.target.files[0]);
                            completion(event.target.files[0])
                        }
                    }}
                />
                {Avatar()}
            </label>


            <br />

        </div>
    );
};

export { ImageInput };