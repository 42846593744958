import React from "react";
import "./images.css";

export enum ImageSize {
    xxsmall = "xxsmall",
    xsmall = "xsmall",
    small = "small",
    medium = "medium",
    large = "large",
    xlarge = "xlarge"
}

const CircleImage = (src: any, alt: string, size: ImageSize, action?: () => void) => {
    return <img
        src={src}
        alt={alt}
        className={"circle-image " + size}
        onClick={action}
    />
}

const RoundedImage = (src: any, alt: string, size: ImageSize, action?: () => void) => {
    return <img
        src={src}
        alt={alt}
        className={"rounded-image " + size}
        onClick={action}
    />
}

const SquareImage = (src: any, alt: string, size: ImageSize, action?: () => void) => {
    return <img
        src={src}
        alt={alt}
        className={"square-image " + size}
        onClick={action}
    />
}

export {
    CircleImage,
    RoundedImage,
    SquareImage
};