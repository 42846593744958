
import React from "react";
import { Link, Outlet } from "react-router-dom";
import { MainFooter } from './components/footer';
import { ImageSize, CircleImage, SquareImage } from "./components/images";
import { AnnounceButton } from "./components/buttons";
import { ProviderModel } from "../models/ProviderModel";
import "./Layout.css";
const logo = require('./assets/logo-black.png');


const LayoutView = (provider: ProviderModel) => {

    return <>
        <header className="header">
            <Link to="/">
                <button className="wrapper-button">
                    <div className="header-group">
                        {SquareImage(logo, "logo", ImageSize.small)}
                        <p className="header-basin-name">Open Basin</p>
                    </div>
                </button>
            </Link>
            <div className="header-group">
                <div className="header-item">
                    <a href={"https://docs.openbasin.io/"} target="_blank" rel="noreferrer">
                        <AnnounceButton title="Docs" />
                    </a>
                </div>
                <Link className="header-item link" to="/buckets">Buckets</Link>
                <Link className="header-item link" to="/standards">Standards</Link>
                <Link className="header-item link" to="/settings">Settings</Link>
                <span className="header-item"></span>
                <Link to="/settings">
                    {CircleImage(provider.image, "avatar", ImageSize.small)}
                </Link>
            </div>
        </header>
        <div className="layout-body">
            <Outlet />
        </div>
        <MainFooter />
    </>
}

export default LayoutView;