import React, { useEffect, useState } from 'react';
import { ImageSize, SquareImage } from '../components/images';
import { SecondaryButton, OutlineButton } from '../components/buttons';
import { Bucket } from 'open-basin';
import basin from "../../basin";
import { Tile } from '../components/tile';
import { Table } from '../components/table';
import "./BucketsView.css"
const bucketImage = require('../assets/paint-bucket.png');

export const BucketsView = () => {
    const [buckets, setBuckets] = useState<Bucket[] | null>(null);

    useEffect(() => {
        if (!buckets) {
            basin.api.buckets().all
                .get()
                .then(result => setBuckets(result))
        }
    }, [buckets]);

    const BucketTile = (bucket: Bucket) => {
        const date = new Date(bucket.timestamp);
        const dateString = date.toLocaleDateString() + " " + date.toLocaleTimeString();
        return (
            <Tile color="white">
                <div className='row-group fill'>
                    <div className='row-group'>
                        <div>
                            <p className='title'>{bucket.name}</p>
                            <p className='subtitle'>{"ID: " + bucket.id}</p>
                            <p className='subtitle'>{"Description: " + bucket.description}</p>
                            <p className='subtitle'>{"Minter: " + bucket.minter}</p>
                            <p className='subtitle'>{"Timestamp: " + dateString}</p>
                            <p className='subtitle'>{"Allowed Standards: " + bucket.allowed_standards}</p>
                        </div>
                    </div>
                    <SecondaryButton title="View" action={() => {

                    }} />
                </div>
            </Tile>
        );
    }

    const bucketsTable = () => {
        if (buckets) {
            return buckets.sort((a, b) => {
                return a.name.localeCompare(b.name)
            }).map((bucket: Bucket) => {
                return (
                    <tr key={bucket.id}>
                        <td>
                            <div className='item'>
                                {BucketTile(bucket)}
                            </div>
                        </td>
                    </tr>
                )
            })
        }
    }

    return (
        <div>
            <div className='section'>
                <Tile align="left" color="gray">
                    <div className='row-group fill'>
                        <div className='row-group'>
                            {SquareImage(bucketImage, "Bucket icon", ImageSize.xsmall)}
                            <div className='padded'>
                                <p className='title'>Buckets</p>
                                <p className='subtitle'>You can request access to each of these buckets from any owner</p>
                            </div>
                        </div>
                        <OutlineButton title="Mint One" action={() => { }} />
                    </div>
                </Tile>
            </div>
            <div className='section'>
                <Table>
                    {bucketsTable()}
                </Table>
            </div>
        </div>
    );
};

export default BucketsView;