import React from "react";
import "./table.css";

const Table = (props: any) => {

    const titleView = () => {
        if (props.title != null) {
            return <tr>{
                props.titles.map((title: string) => {
                    return <th>{title}</th>
                })}
            </tr>
        } else {
            return <tr />
        }
    }

    return <div >
        <table>
            <thead>
                {titleView()}
            </thead>
            <tbody>
                {props.children}
            </tbody>
        </table>
    </div>
}

export { Table };