import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import storage from '../../services/storage';
import remote from '../../services/remote';
import auth from '../../services/auth';
import { ImageInput } from '../components/input';
import { uuidv4 } from '@firebase/util';
import { PrimaryButton, SimpleButton } from '../components/buttons';
import { ProviderModel } from '../../models/ProviderModel';
import "./SettingsView.css"

export const SettingsView = (provider: ProviderModel) => {
    const [name, setName] = useState<string | number | string[] | undefined>(provider.name ?? "");
    const [image, setImage] = useState<any | null>(provider.image);

    useEffect(() => {

    }, [name, image]);

    const updateInfo = async () => {
        const imageUrl = await storage.upload('provider/avatars/' + uuidv4(), image);

        const providerData = {
            name: name,
            image: imageUrl,
        }

        await remote.update.doc("providers", provider.id, providerData)
    }

    return (
        <div className='settings-view'>
            <div className='title-group'>
                {ImageInput(image, (url: any) => {
                    setImage(url);
                })}
                <br />
                <p className='title'>Update Information</p>
            </div>
            <br />
            <Form className='form'>
                <Form.Control
                    className='text-box'
                    type="name"
                    value={name}
                    onChange={(e) => { setName(e.target.value) }}
                    placeholder="Organization Name"
                />
                <br />
            </Form>
            <PrimaryButton title="Save" type="fill" action={async () => await updateInfo()} />
            <br />
            <br />
            <SimpleButton title="Sign Out" action={() => auth.signOut()} />
        </div>
    );
};

export default SettingsView;