import React, { useEffect, useState } from 'react';
import { ImageSize, SquareImage } from '../components/images';
import { SecondaryButton, OutlineButton } from '../components/buttons';
import { utils } from 'open-basin';
import { ProviderModel } from '../../models/ProviderModel';
import { Tile } from '../components/tile';
import { Table } from '../components/table';
import { TextBox } from '../components/text-box';
import api from '../../services/api';
import credentials from "../../services/credentials";
import auth from '../../services/auth';
const layers = require('../assets/layers.png');

export const HomeView = (provider: ProviderModel) => {
    const [keys, setKeys] = useState<any[] | null>(null);
    const [selectedKey, setSelectedKey] = useState<string | null>(null);

    // console.log("load");

    useEffect(() => {
        if (keys == null) {
            api.getProviderKeys(provider.id)
                .then(newKeys => {
                    setKeys(newKeys);
                })
        }
    }, [keys, provider]);

    const generateNewKeys = async () => {
        const apiKey = await credentials.generate.apiKey();
        const keys = await api.getProviderKeys(provider.id);

        if (keys.length > 0) {
            await api.setProviderKeys(provider, apiKey, keys[0].public_address)
        } else {
            const publicAddress = await credentials.generate.publicAddress();
            await api.setProviderKeys(provider, apiKey, publicAddress)
        }

        api.getProviderKeys(provider.id)
            .then(newKeys => {
                setKeys(newKeys);
            })
    }

    const CodeBlock = (text: string) => {
        return <div>
            {TextBox(text, true)}
        </div>
    }

    const toDate = (str: any) => {
        const d = new Date(str)
        return <p>
            {d.toLocaleString()}
        </p>
    }

    const KeyTile = (key: any) => {
        if (key.public_address === selectedKey) {
            return <Tile color="white" align="left">
                <div>
                    <p className='title'>Address</p>
                    <div className='padded-vert'>
                        {CodeBlock(key.public_address)}
                    </div>
                    <p className='title'>API Key</p>
                    <div className='padded-vert'>
                        {CodeBlock(key.api_key)}
                    </div>
                    <div className='align-right'>
                        <SecondaryButton title="Hide" action={() => {
                            setSelectedKey(null);
                        }} />
                    </div>
                </div>
            </Tile>
        } else {
            return <Tile color="white">
                <div className='row-group fill'>
                    <div className='row-group'>
                        <div>
                            <p className='title'>{"Address: " + utils.interface.shrinkAddress(key.public_address)}</p>
                            <p className='subtitle'>{"API Key: ••••••••••••••••••••••••••••••••••••••••"}</p>
                            {toDate(key.timestamp)}
                        </div>
                    </div>
                    <SecondaryButton title="View" action={() =>
                        setSelectedKey(key.public_address)
                    } />
                </div>
            </Tile>
        }
    }

    const keysTable = () => {
        if (keys) {
            return keys.sort((a, b) => {
                return +new Date(b.timestamp) - +new Date(a.timestamp);
            }).map((key: any) => {
                return (
                    <tr key={key.public_address}>
                        <td>
                            <div className='item'>
                                {KeyTile(key)}
                            </div>
                        </td>
                    </tr>
                )
            })
        }
    }

    return (
        <div>
            <div className='section'>
                <Tile align="left" color="gray">
                    <div className='row-group fill'>
                        <div className='row-group'>
                            {SquareImage(layers, "Project icon", ImageSize.xsmall)}
                            <div className='padded'>
                                <p className='title'>{provider.name}</p>
                                <p className='subtitle'>{"ID: " + provider.id}</p>
                            </div>
                        </div>
                        <OutlineButton title="Generate New Key" action={() => generateNewKeys()} />
                    </div>
                </Tile>
            </div>
            <div className='section'>
                <Table>
                    {keysTable()}
                </Table>
            </div>
        </div>
    );
};

export default HomeView;