import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './firebase.js';
import './basin';
import OnboardingView from './pages/onboarding/OnboardingView';
import LayoutView from './pages/Layout';
import NoPage from './pages/NoPage';
import { ProviderModel } from './models/ProviderModel';
import auth from './services/auth';
import HomeView from './pages/home/HomeView';
import BucketsView from './pages/buckets/BucketsView';
import StandardsView from './pages/standards/StandardsView';
import SettingsView from './pages/settings/SettingsView';

enum AppStates {
  Loading,
  Onboarding,
  Exploring
}

interface AppProps {
  state: AppStates;
  currentProvider: ProviderModel | null;
}

function App() {
  const [props, setProps] = useState<AppProps>({
    state: AppStates.Loading,
    currentProvider: null
  });

  useEffect(() => {
    // auth.signOut(() => {})
    if (props.state === AppStates.Loading) {
      auth.onStateChange(async (provider) => {
        if (provider) {
          try {
            const provider = await auth.fetchProvider();

            setProps({
              state: AppStates.Exploring,
              currentProvider: provider
            });
          } catch {
            auth.signOut();
          }
        } else {
          setProps({
            state: AppStates.Onboarding,
            currentProvider: null
          });
        }
      })
    }
  }, [props]);

  const DashboardView = () => {
    if (props.currentProvider != null) {
      return (
        <BrowserRouter>
          <Routes>
            <Route path="/" element={LayoutView(props.currentProvider)}>
              <Route index element={HomeView(props.currentProvider)} />
              <Route path="buckets" element={BucketsView()} />
              <Route path="standards" element={StandardsView()} />
              <Route path="*" element={SettingsView(props.currentProvider)} />
            </Route>
          </Routes>
        </BrowserRouter>
      );
    } else {
      auth.signOut(() => { })
      return <OnboardingView />
    }
  }

  const View = () => {
    switch (props.state) {
      case AppStates.Loading:
        return null
      case AppStates.Onboarding:
        return <div>
          <OnboardingView />
        </div>
      case AppStates.Exploring:
        return <DashboardView />
    }
  }

  return (
    <div className="App">
      <View />
    </div>
  );
}

export default App;