import firebase from '../firebase';
import remote from './remote';
import { ProviderModel } from '../models/ProviderModel';

// const signInWithGoogle = (completion: (provider: firebase.User | null) => void) => {
//     chrome.identity.getAuthToken({}, (token) => {
//         let credential = firebase.auth.GoogleAuthProvider.credential(null, token)
//         firebase
//             .auth()
//             .signInWithCredential(credential)
//             .then((providerCredential) => {
//                 const provider = firebase.auth().currentUser
//                 completion(provider);
//             })
//             .catch((error: any) => {
//                 console.error(error)
//             })
//     })
// }

const createProviderWithEmail = (name: string, email: string, password: string, image: string, completion: (provider: ProviderModel) => void) => {
    firebase.auth()
        .createUserWithEmailAndPassword(email, password)
        .then(async (providerCredential: any) => {
            const currentProvider = currentAuthProvider();
            if (currentProvider) {
                const providerData = {
                    id: currentProvider.uid,
                    name: name,
                    email: currentProvider.email,
                    image: image,
                    accounts: []
                }
                await remote.set.doc("providers", currentProvider.uid, providerData)
                completion(providerData);
            } else {
                throw Error("No provider")
            }
        })
}

const signInWithEmail = (email: string, password: string, completion: (provider: ProviderModel) => void) => {
    firebase.auth()
        .signInWithEmailAndPassword(email, password)
        .then(async (providerCredential: any) => {
            const provider = await fetchProvider();
            if (provider) {
                completion(provider);
            } else {
                throw Error("No provider")
            }
        })
}

const signOut = (completion?: () => void) => {
    firebase.auth()
        .signOut()
        .then(() => { if (completion != null) completion() })
}

const fetchProvider = async (completion?: (provider: ProviderModel) => void): Promise<ProviderModel> => {
    const currentProvider = currentAuthProvider();
    if (currentProvider) {
        const data = await remote.get.doc("providers", currentProvider.uid)
        const fetchedProvider = {
            id: data.id,
            name: data.name,
            email: data.email,
            image: data.image,
            accounts: data.accounts
        };

        completion && completion(fetchedProvider);
        return fetchedProvider;
    } else {
        throw Error("Auth Error: Provider is not logged in");
    }
}

const onStateChange = (completion: (provider: firebase.User | null) => void) => {
    firebase.auth()
        .onAuthStateChanged(provider => {
            completion(provider);
        });
}

const currentAuthProvider = () => {
    return firebase.auth().currentUser;
}

const auth = {
    createProviderWithEmail,
    // signInWithGoogle,
    signInWithEmail,
    signOut,
    currentAuthProvider,
    onStateChange,
    fetchProvider
}

export default auth;