import React from "react";
import "./buttons.css";

const AnnounceButton = (props: any) => {
    return <button
        className={"announce-button " + props.type}
        onClick={() => props.action()}>
        {props.title}
    </button>
};

const PrimaryButton = (props: any) => {

    return <button
        className={"primary-button " + props.type}
        onClick={() => props.action()}>
        {props.title}
    </button>
};

const SecondaryButton = (props: any) => {

    return <button
        className={"secondary-button " + props.type}
        onClick={() => props.action()}>
        {props.title}
    </button>
};

const OutlineButton = (props: any) => {

    return <button
        className={"outline-button " + props.type}
        onClick={() => props.action()}>
        {props.title}
    </button>
};

const SimpleButton = (props: any) => {

    return <button
        className={"simple-button " + props.type}
        onClick={() => props.action()}>
        {props.title}
    </button>
};


const LinkButton = (props: any) => {

    return <a href={props.link} target="_blank" rel="noreferrer">
        <button
            className={"link-button " + props.type}>
            {props.title}
        </button>
    </a>
};

const CircleButton = (props: any) => {
    return <button
        className="circle-button"
        onClick={props.action}>
        {props.title}
    </button>
};

const GlyphButton = (props: any) => {
    return <button
        className="glyph-button"
        onClick={props.action}>
        <img className="glyph" src={props.src} alt="button" />
    </button>
}

export {
    AnnounceButton,
    PrimaryButton,
    SecondaryButton,
    OutlineButton,
    SimpleButton,
    LinkButton,
    CircleButton,
    GlyphButton
}