import axios from 'axios'
import Web3 from "web3";

const publicAddress = async () => {
    const infura = process.env.REACT_APP_INFURA_ENDPOINT;

    if (infura == null) throw Error("No infura endpoint");

    const web3 = new Web3(
        new Web3.providers.HttpProvider(
            infura
        )
    );

    const account = web3.eth.accounts.create();

    return account.address;
}

const apiKey = async () => {
    const url = process.env.REACT_APP_AUTH_URL;

    if (url == null) {
        throw Error("No url");
    }

    const body = process.env.REACT_APP_AUTH_BODY;

    if (body == null) {
        throw Error("No auth body");
    }

    const authorized = axios.create({
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return await authorized
        .post(url, body)
        .then(result => {
            return result.data.access_token;
        });
}

const generate = {
    apiKey,
    publicAddress
}

const credentials = {
    generate
}

export default credentials;