import React from "react";
import { GlyphButton } from "./buttons";
import copy from "copy-to-clipboard";
import "./text-box.css";
const glyph = require('../assets/copy.png');

const TextBox = (text: string, copyable: boolean) => {

    const copyButton = () => {
        if (copyable) {
            return <div>
                <GlyphButton src={glyph} action={() => {
                    copy(text);
                }} />
            </div>
        }
    }

    return <div className="text-box-id">
        <div className="text-box-row">
            <p className="text-box-text">{text}</p>
            {copyButton()}
        </div>
    </div>
}

export { TextBox };