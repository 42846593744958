import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { store } from "../firebase";

const environment = () => {
    const env = process.env.REACT_APP_FIREBASE_ENVIRONMENT;
    if (env) {
        return env
    } else {
        return "dev";
    }
}

const upload = async (path: string, file: any): Promise<string> => {
    const fileRef = ref(store, environment() + path);

    const snapshot = await uploadBytes(fileRef, file)

    return await getDownloadURL(snapshot.ref);
}

const storage = {
    upload
}

export default storage;