import { ProviderModel } from "../models/ProviderModel";
import remote from "./remote";

const setProviderKeys = async (provider: ProviderModel, apiKey: string, publicAddress: string) => {
    await remote.set.sub.doc("providers", provider.id, "keys", publicAddress, {
        api_key: apiKey,
        public_address: publicAddress,
        timestamp: new Date().toISOString()
    });

    await remote.set.doc("providers_public", publicAddress, {
        id: publicAddress,
        name: provider.name,
        image: provider.image
    });
}

const getProviderKeys = async (id: string): Promise<any[]> => {
    const keys = await remote.get.sub.collection("providers", id, "keys");

    return keys;
}

const getProviderKey = async (id: string, address: string) => {
    return await remote.get.sub.doc("providers", id, "keys", address);
}

const api = {
    setProviderKeys,
    getProviderKeys,
    getProviderKey
}

export default api;