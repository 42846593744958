import { doc, collection, getDoc, setDoc, getDocs, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

const setDocument = async (collectionId: string, docId: string, data: any) => {
    await setDoc(
        doc(db, dbs(), environment(), collectionId, docId),
        data
    );
}

const setSubDocument = async (collectionId: string, docId: string, subCollectionId: string, subId: string, data: any) => {
    await setDoc(
        doc(db, dbs(), environment(), collectionId, docId, subCollectionId, subId),
        data
    );
}

const updateDocument = async (collectionId: string, docId: string, data: any) => {
    await updateDoc(
        doc(db, dbs(), environment(), collectionId, docId),
        data
    );
}

const updateSubDocument = async (collectionId: string, docId: string, subCollectionId: string, subId: string, data: any) => {
    await updateDoc(
        doc(db, dbs(), environment(), collectionId, docId, subCollectionId, subId),
        data
    );
}

const getDocument = async (collectionId: string, id: string, completion?: (data: any) => void) => {
    const docRef = doc(db, dbs(), environment(), collectionId, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        const data = docSnap.data();
        completion && completion(data);
        return data;
    } else {
        throw Error("Document does not exist");
    }
}

const getSubDocument = async (collectionId: string, docId: string, subCollectionId: string, subId: string) => {
    const docRef = doc(db, dbs(), environment(), collectionId, docId, subCollectionId, subId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        throw Error("Document does not exist");
    }
}

const getCollection = async (collectionId: string) => {
    const collectionRef = collection(db, dbs(), environment(), collectionId);
    const snapshot = await getDocs(collectionRef);

    return snapshot.docs.map(d => ({
        id: d.id, ...d.data()
    }));
}

const getSubCollection = async (collectionId: string, docId: string, subCollectionId: string) => {
    const collectionRef = collection(db, dbs(), environment(), collectionId, docId, subCollectionId);
    const snapshot = await getDocs(collectionRef);

    return snapshot.docs.map(d => ({
        id: d.id,
        ...d.data()
    }));
}

const dbs = () => {
    return "dbs";
}

const environment = () => {
    const env = process.env.REACT_APP_FIREBASE_ENVIRONMENT;
    if (env) {
        return env
    } else {
        return "dev";
    }
}

const remote = {
    set: {
        doc: setDocument,
        sub: {
            doc: setSubDocument
        }
    },
    update: {
        doc: updateDocument,
        sub: {
            doc: updateSubDocument
        }
    },
    get: {
        doc: getDocument,
        collection: getCollection,
        sub: {
            doc: getSubDocument,
            collection: getSubCollection
        }
    }
}

export default remote;