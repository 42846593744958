import React, { useEffect, useState } from 'react';
import { ImageSize, SquareImage } from '../components/images';
import { SecondaryButton, OutlineButton } from '../components/buttons';
import { utils, Standard } from 'open-basin';
import basin from "../../basin";
import { Tile } from '../components/tile';
import { Table } from '../components/table';
import "./StandardsView.css"
const standardImage = require('../assets/standard.png');

export const StandardsView = () => {
    const [standards, setStandards] = useState<Standard[] | null>(null);

    useEffect(() => {
        if (!standards) {
            basin.api.standards().all
            .get()
            .then(result => setStandards(result));
        }
    }, [standards]);

    const StandardTile = (standard: Standard) => {
        const date = new Date(standard.timestamp);
        const dateString = date.toLocaleDateString() + " " + date.toLocaleTimeString();
        return (
            <Tile color="white">
                <div className='row-group fill'>
                    <div className='row-group'>
                        <div>
                            <p className='title'>{standard.name}</p>
                            <p className='subtitle'>{"ID: " + standard.id}</p>
                            <p className='subtitle'>{"Minter: " + standard.minter}</p>
                            <p className='subtitle'>{"Timestamp: " + dateString}</p>
                            <p className='subtitle'>{"Schema: " + utils.interface.shrinkData(JSON.stringify(standard.schema))}</p>
                        </div>
                    </div>
                    <SecondaryButton title="View" action={() => {

                    }} />
                </div>
            </Tile>
        );
    }

    const standardsTable = () => {
        if (standards) {
            return standards.sort((a, b) => {
                return a.name.localeCompare(b.name)
            }).map((standard: Standard) => {
                return (
                    <tr key={standard.id}>
                        <td>
                            <div className='item'>
                                {StandardTile(standard)}
                            </div>
                        </td>
                    </tr>
                )
            })
        }
    }

    return (
        <div>
            <div className='section'>
                <Tile align="left" color="gray">
                    <div className='row-group fill'>
                        <div className='row-group'>
                            {SquareImage(standardImage, "Standard icon", ImageSize.xsmall)}
                            <div className='padded'>
                                <p className='title'>Standards</p>
                                <p className='subtitle'>Any of these standards can be used to enforce a certain data structure</p>
                            </div>
                        </div>
                        <OutlineButton title="Mint One" action={() => { }} />
                    </div>
                </Tile>
            </div>
            <div className='section'>
                <Table>
                    {standardsTable()}
                </Table>
            </div>
        </div>
    );
};

export default StandardsView;