import React from "react";
import "./footer.css";
import { LinkButton } from "./buttons";

const MainFooter = () => {
    return <div className="footer">
        <div className="footer-buttons">
            <LinkButton title="Basin" link="https://www.openbasin.io/" />
            <LinkButton title="GitHub" link="https://github.com/open-basin" />
            <LinkButton title="Twitter" link="https://twitter.com/openbasin" />
        </div>
        <br />
    </div>
}

export { MainFooter }